import * as React from 'react';

import moment from 'moment';
import { Translate } from 'react-localize-redux';

import { fullDateFormat, onlyTimeFormat } from './dateFormats';

type DateType = moment.Moment | string | number;

export const onlyDigitsRegExp = /^\d+$/;
export const onlyUpperCase = /^[A-Z]+$/;
export const onlyLowerCase = /^[a-z]+$/;
export const onlySpecialCharacters = /^[^a-zA-Z0-9]+$/;
export const onlyDecimalNumbersRegExp = /^(\d+)(\.\d+)?$/;
export const onlyDecimalNumbersTwoPlacesRegExp = /^(\d+)(\.\d{1,2})?$/;
export const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
// eslint-disable-next-line no-useless-escape
export const urlRegExp = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
export const phoneRegExp = /^\+[\d\s-]{1,20}$/;
export const priceRegExp = /^\d{0,9}\.?\d{0,5}$/;
export const domainRegExp = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/;
export const emptySpaceRegExp = /^\s*$/;
export const metafieldKeyRegExp = /^[a-z0-9-_]+\.[a-z0-9-_]+$/;
const listOfOriginalValues = /\S.*?(?![^,]),?/g;
const googleAnalyticsLegacyRegExp = /(UA-\d{4,10}-\d{1,4}[, )|,]*)/;
const googleAnalytics4RegExp = /(G-[A-Z0-9]{7,10}[, ]*)/;
const facebookPixelCodeRegExp = /([\d]{15,16}[, ]*)/;

// eslint-disable-next-line no-useless-escape
export const noApostrophes = /[\']/;
// eslint-disable-next-line no-useless-escape
export const socialMediaFields = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
export const digitsLettersMinusesSpaces = /^[a-zA-Z0-9-\s]*$/;
export const htmlElement = /(<([^>]+)>)/;

export const validatePassword = (password, translate) => {
  if (!password || password === '') {
    return translate('Required') as string;
  } else if (password.length < 12) {
    return translate('Password_min12Char') as string;
  } else if (password.length > 1024) {
    return translate('Password_max1024Char') as string;
  } else if (onlyDigitsRegExp.test(password)) {
    return translate('Password_onlyNumeric');
  } else if (onlyUpperCase.test(password)) {
    return translate('Password_onlyUpperCase');
  } else if (password.length > password.trimLeft().trimRight().length) {
    return translate('No_leading_or_trailing_space');
  } else if (onlyLowerCase.test(password)) {
    return translate('Password_onlyLowerCase');
  } else if (onlySpecialCharacters.test(password)) {
    return translate('Password_onlySpecialChar');
  }
  return null;
};

export const validate = {
  required: (value) => (!value || !value.trim() ? <Translate id="Required" /> : undefined),
  email: (value: string) =>
    value && value.length < maxLength.email && !emailRegExp.test(value) ? (
      <Translate id="Invalid_email" />
    ) : undefined,
  pin: (value) =>
    value && !/^[1-9][0-9]{5}$/i.test(value) ? <Translate id="Invalid_pin" /> : undefined,
};

const isFileExtensionValid = (file: File, allowedExtension: string[]) =>
  allowedExtension.includes(file.type.split('image/')[1]);

const googleAnalyticsCodeCheck = (value: string): JSX.Element | undefined => {
  const result = value.match(googleAnalyticsLegacyRegExp) || value.match(googleAnalytics4RegExp);
  if (!result || result.input !== result[0]) {
    return <Translate id="Invalid_code" />;
  }
  return;
};

const facebookPixelCodeCheck = (value: string): JSX.Element | undefined => {
  const result = value.match(facebookPixelCodeRegExp);
  if (!result || result.input !== result[0]) {
    return <Translate id="Invalid_code" />;
  }
  return;
};

export const formikValidate = {
  required: (value) => {
    if (
      value === undefined ||
      value === '' ||
      value === null ||
      (Array.isArray(value) && !value.length)
    ) {
      // @ts-ignore
      return (<Translate id="Required" />) as string;
    }

    return;
  },
  digits: (value) => {
    if (!onlyDigitsRegExp.test(value)) {
      // @ts-ignore
      return (<Translate id="Only_digits" />) as string;
    }

    return;
  },
  max: (maximum: number, message?: TranslationId) => (value) => {
    if (value > maximum) {
      // @ts-ignore
      return (
        <Translate id={message || 'Max_value_exceed'} data={{ max: maximum, value }} />
      ) as string;
    }

    return;
  },
  min: (minimum: number, message?: TranslationId) => (value) => {
    if (value < minimum) {
      // @ts-ignore
      return (
        <Translate id={message || 'Min_value_exceed'} data={{ min: minimum, value }} />
      ) as string;
    }

    return;
  },
  maxLength: (maximum: number, message?: TranslationId) => (value: string) => {
    if (value.length > maximum) {
      // @ts-ignore
      return (
        // todo update message
        <Translate id={message || 'Max_value_exceed'} data={{ max: maximum, value }} />
      ) as string;
    }

    return;
  },
  minLength: (minimum: number, message?: TranslationId) => (value: string) => {
    if (value.length < minimum) {
      // @ts-ignore
      return (
        // todo update message
        <Translate id={message || 'Min_value_exceed'} data={{ min: minimum, value }} />
      ) as string;
    }

    return;
  },
  timeString: (value) => {
    if (!/^\d{1,2}(pm|am)+$/.test(value)) {
      // @ts-ignore
      return (<Translate id="Invalid_format" />) as string;
    }

    return;
  },
  hexString: (value) => {
    if (!/^#[0-9a-f]{3,6}$/i.test(value)) {
      // @ts-ignore
      return (<Translate id="Not_valid_hex_string" />) as string;
    }

    return;
  },
  extension: (value: File | FileList, allowedExtension: string[]) => {
    if (value) {
      let isValid = true;

      if (value instanceof FileList) {
        const files = Array.from(value);

        for (const file of files) {
          if (!isFileExtensionValid(file, allowedExtension)) {
            isValid = false;
            break;
          }
        }
      } else {
        isValid = isFileExtensionValid(value, allowedExtension);
      }

      if (!isValid) {
        // @ts-ignore
        return (
          <Translate
            id="Wrong_extension"
            data={{
              extensions: allowedExtension.join(', '),
            }}
          />
        ) as string;
      }
    }

    return;
  },
  minDate: (value: DateType, minDate: DateType, format: string = fullDateFormat) => {
    const date = moment(minDate, format);

    if (moment(value) < date) {
      return <Translate id="Min_date" data={{ min: date.format(format) }} />;
    }
    return;
  },
  minTime: (value: DateType, minDate: DateType, format: string = onlyTimeFormat) => {
    const date = moment(minDate);

    if (moment(value) < date) {
      return <Translate id="Min_time" data={{ min: date.format(format) }} />;
    }
    return;
  },
  url: (value: string) => {
    if (!urlRegExp.test(value)) {
      return (<Translate id="URL_not_valid" />) as unknown as string;
    }
    return;
  },
  pin: (maximum: number) => (value: number) => {
    if (value < maximum || value > maximum) {
      return <Translate id="Invalid_pin" />;
    }
    return;
  },
  bsb_ncc: (exact: number) => (value: number) => {
    if (value < exact || value > exact) {
      return <Translate id="Invalid_bsb_ncc" data={{ exact }} />;
    }
    return;
  },
  max_string_custom: (maximum: number) => (value: number, fieldName: string) => {
    if (value > maximum) {
      // @ts-ignore
      return (
        <Translate id="Max_fieldname_length_exceeded" data={{ max: maximum, value, fieldName }} />
      ) as string;
    }
    return;
  },
  min_string_custom: (minimum: number) => (value: number, fieldName: string) => {
    if (value < minimum) {
      // @ts-ignore
      return (
        <Translate id="Min_fieldname_length_exceeded" data={{ min: minimum, value, fieldName }} />
      ) as string;
    }
    return;
  },

  oneOf: (values: any[], fieldNames: string[] = []) => {
    const isValid = values.some((value) => !formikValidate.required(value));

    if (!isValid) {
      // @ts-ignore
      return <Translate id="One_of_error_message" data={{ fields: fieldNames.join(', ') }} />;
    }

    return;
  },

  email: (value: string) => {
    if (!emailRegExp.test(value)) {
      return (<Translate id="Invalid_email" />) as unknown as string;
    }
    return;
  },

  phone: (value: string, errorTranslationId: TranslationId) => {
    if (!phoneRegExp.test(value)) {
      return <Translate id={errorTranslationId} />;
    }

    return;
  },

  domain: (value: string) => {
    if (!domainRegExp.test(value)) {
      return <Translate id="The_supplied_domain_is_not_in_correct_format" />;
    }

    return;
  },

  googleAnalyticsCode: (value: string) => {
    const listOfValues = value.match(listOfOriginalValues);
    if (listOfValues) {
      for (const value of listOfValues) {
        const validationMessage = googleAnalyticsCodeCheck(value);
        if (validationMessage) {
          return validationMessage;
        }
      }
      return;
    }
  },

  facebookPixelCode: (value: string) => {
    const listOfValues = value.match(listOfOriginalValues);
    if (listOfValues) {
      for (const value of listOfValues) {
        const validationMessage = facebookPixelCodeCheck(value);
        if (validationMessage) {
          return validationMessage;
        }
      }
      return;
    }
  },

  SMSRestaurantName: (value: string) => {
    if (value.length === 0) {
      return <Translate id="Required" />;
    }
    if (noApostrophes.test(value)) {
      return <Translate id="No_apostrophes_allowed" />;
    }
    if (!digitsLettersMinusesSpaces.test(value)) {
      return <Translate id="Letters_and_numbers_only_no_special_characters" />;
    }
    if (value.length < 3) {
      return <Translate id={'Min_value_exceed'} data={{ min: 3 }} />;
    }
    if (value.length > 11) {
      return <Translate id={'Max_value_exceed'} data={{ max: 11 }} />;
    }

    return;
  },
  brandName: (value: string) => {
    if (value.length > 30) {
      return <Translate id={'Max_value_exceed'} data={{ max: 30 }} />;
    }
    if (value.length === 0) {
      return <Translate id="Required" />;
    }
    return;
  },
  socialMediaFields: (value: string) => {
    if (socialMediaFields.test(value)) {
      return;
    }
    return <Translate id="URL_not_valid" />;
  },
  containsHTMLElement: (value: string) => {
    if (htmlElement.test(value)) {
      return <Translate id="This_type_of_content_is_not_allowed" />;
    }
    return;
  },
};

export const maxLength = {
  email: 256,
  storeName: 100,
  pin: 6,
};
