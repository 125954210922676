import React, { useEffect } from 'react';

import { App, StoreGroup, StoreGroupExtended } from '@flipdish/api-client-typescript';
import Loading from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { HOC as Permissions } from 'react-redux-permissions';
import { type RouteComponentProps, withRouter } from 'react-router';
import { compose, setDisplayName } from 'recompose';

import { update } from '../../actions/storegroup.actions';
import EmptyIcon from '../../assets/images/empty_store_list.svg';
import { usePrevious } from '../../custom-hooks/usePrevious';
import { createStoreGroupSelectorById } from '../../selectors/storegroup.selector';
import { useTracking } from '../../services/amplitude/useTracking';
import EmptyComponent from '../../ui/EmptyComponent';
import PageLayout from '../../ui/Layout';
import GridContainer, { useCardStyles } from '../../ui/Layout/GridContainer';
import SearchTextField from '../../ui/SearchTextField/SearchTextField';
import Spacer from '../../ui/Spacer';
import WindowInfiniteScroll from '../../ui/WindowInfiniteScroll';
import { EditableLabel } from '../common/molecules';
import { MapCardSkeleton } from '../StoreGroups/components/MapCard';
import withRouteSearchParams, { WithRouteSearchParamsProps } from '../WithRouteSearchParams';
import { CreateNewButton } from './components/CreateNewButton';
import StoreCard from './components/StoreCard';
import withStoresData, { StoresDataComponentProps } from './withStoresData';

const useStyles = makeStyles((theme: Theme) => ({
  gridItem: {
    padding: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: { padding: theme.spacing(1) },
  },
}));

const PCreateNewButton = Permissions([App.AppResourceSetEnum.CreateStoreGroups])(
  CreateNewButton,
  null
);

const currencyData: Array<{ key: string; value: string }> = ((
  passedEnum
): Array<{ key: string; value: string }> => {
  const data: Array<{ key: string; value: string }> = [];
  // tslint:disable-next-line:forin
  for (const x in passedEnum) {
    data.push({ key: x, value: x });
  }
  return data;
})(StoreGroup.CurrencyEnum);

const Loader = () => (
  <Grid key="loader" container justifyContent="center">
    <Loading />
  </Grid>
);

const StoresSkeleton = ({ itemsCount }: any) => {
  const cardClasses = useCardStyles();
  const classes = useStyles();

  return (
    <GridContainer key="loader" cards>
      {[...Array(itemsCount).keys()].map((index) => {
        return (
          <Grid
            item
            key={index}
            className={clsx(cardClasses.card, classes.gridItem)}
            xs={12}
            sm={6}
            md={4}
            lg={3}
          >
            <MapCardSkeleton />
          </Grid>
        );
      })}
    </GridContainer>
  );
};

const limit = 20;

type ListProps = RouteComponentProps &
  MapStateToProps &
  StoresDataComponentProps &
  WithRouteSearchParamsProps<string> & { storeGroupStoresTotalCount?: number };

function StoreList(props: ListProps) {
  const {
    search,
    history,
    stores,
    loadNext,
    storesData,
    currentApp,
    storesSetSearch,
    storesTotalCount,
    storesDataLoading,
    storeGroupStoresTotalCount,
  } = props;
  const cardClasses = useCardStyles();
  const classes = useStyles();

  const previousStores = usePrevious(stores);
  const showPreLoaded = storeGroupStoresTotalCount && !storesData.length;
  const loadMore = async (page: number) => {
    try {
      await loadNext(page, limit);
    } catch (error) {
      console.error(error);
    }
  };

  const Preloader = () => (
    <StoresSkeleton itemsCount={Math.min(storeGroupStoresTotalCount || 0, limit)} />
  );

  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_list', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    storesSetSearch(search);
  }, []);

  useEffect(() => {
    if (
      stores &&
      stores.totalCount &&
      previousStores &&
      previousStores.totalCount! < stores.totalCount &&
      stores.create &&
      stores.create.storeId
    ) {
      history.push(
        `/${currentApp.AppId}/storegroups/${stores.storeGroupId}/stores/${stores.create.storeId}`
      );
    }
  }, [stores, previousStores]);

  return (
    <WindowInfiniteScroll
      pageStart={1}
      loadMore={loadMore}
      hasMore={storesData.length !== storesTotalCount || storesDataLoading}
      loader={showPreLoaded ? Preloader : Loader}
      initialLoad={false}
    >
      <GridContainer alignItems="stretch" cards key="list">
        {storesData.map((s) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={s.StoreId}
            className={clsx(cardClasses.card, classes.gridItem)}
          >
            <StoreCard storeId={s.StoreId} />
          </Grid>
        ))}
      </GridContainer>
    </WindowInfiniteScroll>
  );
}

type MappedDispatch = ReturnType<ReturnType<typeof mapDispatchToPropsFactory>>;
const mapDispatchToPropsFactory = (dispatch, ownProps) => {
  const { storeGroupId } = ownProps.match.params;
  return (dispatch: ThunkDispatch) => {
    return {
      update: (changes) => dispatch(update(Number(storeGroupId), changes)),
    };
  };
};

type MapStateToProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState, ownProps) => {
  const storeGroupId = Number(ownProps.match.params.storeGroupId);
  const storeGroupSelector = createStoreGroupSelectorById(storeGroupId);
  const data = storeGroupSelector(state) as Required<StoreGroupExtended> | undefined;
  const storeGroupStoresTotalCount = data ? data.TotalStores : undefined;
  const canEdit = state.permissions.some(
    (p) => p === App.AppResourceSetEnum.UpdateStoreGroups.toString()
  );

  return {
    canEdit,
    storeGroupStoresTotalCount,
    appId: state.currentApp.AppId,
    translate: getTranslate(state.locale),
    currentApp: state.currentApp,
    storeGroupId: (data && data.StoreGroupId) || '',
    storeGroupCurrency: data && data.Currency,
    storeGroupName: (data && data.Name) || '',
    stores: state.stores,
  };
};

type Props = StoresDataComponentProps &
  MapStateToProps &
  MappedDispatch &
  WithRouteSearchParamsProps<string> &
  RouteComponentProps<{ storeGroupId: string }>;
const Stores = compose<Props, {}>(
  setDisplayName('Stores'),
  withRouter,
  connect(mapStateToProps, mapDispatchToPropsFactory),
  withRouteSearchParams({
    name: 'filter',
  }),
  withStoresData
)((props) => {
  const {
    storesShowSearch,
    setSearch,
    loadNext,
    storesSetSearch,
    search,
    update,
    storeGroupName,
    translate,
    canEdit,
    storeGroupCurrency,
    appId,
    storeGroupId,
  } = props;
  const classes = useStyles();

  const onChange = (search: string) => {
    setSearch(search);
    storesSetSearch(search);
    loadNext(1, limit);
  };
  const header = (
    <GridContainer>
      <Grid item sm={5} className={classes.gridItem}>
        <Collapse in={storesShowSearch} mountOnEnter unmountOnExit>
          {storesShowSearch && (
            <>
              <SearchTextField
                fdKey="Search_sales_channels"
                label={<Translate id="Search" />}
                defaultValue={search}
                onChange={(e) => onChange(e)}
                fullWidth
              />
              <Spacer size={32} />
            </>
          )}
        </Collapse>
      </Grid>
    </GridContainer>
  );

  return (
    <PageLayout
      fluid
      toParent={`/${appId}/storegroups/${storeGroupId}`}
      title={
        <EditableLabel
          labelKey={'Store_group_name_label'}
          inputKey={'Store_group_name_input'}
          value={storeGroupName}
          labelAppendix={() => (
            <Typography variant="h5" component="span">
              {` ${storeGroupName && translate('Stores')}`.toLowerCase()}
            </Typography>
          )}
          changePropName="Name"
          onChange={update}
          disabled={!canEdit}
        />
      }
      header={header}
      caption={
        storeGroupCurrency && (
          <EditableLabel
            textType="caption"
            labelKey={'Store_group_currency_label'}
            inputKey={'Store_group_currency_input'}
            type="select"
            value={storeGroupCurrency.toString()}
            labelAppendix={() => <span>{` ${translate('Currency')}`.toLowerCase()}</span>}
            changePropName="Currency"
            onChange={update}
            selectData={currencyData}
            disabled={!canEdit}
          />
        )
      }
      actions={PCreateNewButton}
      documentTitle="Stores"
    >
      <StoreList {...props} />
      {props.storesDataIsEmpty && (
        <EmptyComponent
          title="No_stores_added_yet"
          subtitle="Add_stores_to_your_group"
          icon={EmptyIcon}
          noLink
        />
      )}
    </PageLayout>
  );
});

export default Stores;
