import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { type Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';

import LoadingButton from '../../../ui/LoadingButton';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '244px',
    color: '#ff395f',
    letterSpacing: '1.25px',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    '&:hover': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
    },
    [theme.breakpoints.down(640)]: { width: '100%' },
    '& :hover': { color: 'rgba(0, 0, 0, 0.38)' },
  },
  addPadding: { paddingBottom: '16px', paddingRight: '24px' },
}));

type Props = {
  buttonText: TranslationId;
  dialogTitle: TranslationId;
  dialogDescription: TranslationId;
  helperText: TranslationId;
  handleSubmit: () => Promise<void>;
  storeName: string;
  isArchived?: boolean;
  setIsArchived?: React.Dispatch<React.SetStateAction<boolean>>;
};

const ArchiveStoreButton = (props: Props) => {
  const classes = useStyles();
  const {
    buttonText,
    dialogDescription,
    dialogTitle,
    helperText,
    handleSubmit,
    storeName,
    isArchived = false,
    setIsArchived,
  } = props;
  const [open, setOpen] = useState<boolean>(false);
  const [validationError, setValidationError] = useState<boolean>(false);
  const [textField, setTextField] = useState<string>();
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const openDialog = () => {
    !isArchived && setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  const _handleSubmit = async () => {
    try {
      if (textField === storeName) {
        setSubmitting(true);
        await handleSubmit();
        setIsArchived && setIsArchived(true);
      } else {
        setValidationError(true);
      }
    } catch (e) {
      setSubmitting(false);
      console.error(e);
    }
  };

  return (
    <>
      <Button
        data-fd={`${buttonText}_btn`}
        variant="outlined"
        className={classes.button}
        key={buttonText}
        onClick={openDialog}
        disabled={isArchived || isSubmitting}
      >
        <Translate id={buttonText} />
      </Button>
      <Dialog open={open} onClose={closeDialog} aria-labelledby="Archive_Store">
        <DialogTitle id={dialogTitle}>
          <Translate id={dialogTitle} />
        </DialogTitle>

        <DialogContent style={{ maxWidth: '280px' }}>
          <DialogContentText component="div">
            <Typography variant="body1" color="textSecondary">
              <Translate id="If_you_are_sure_you_want_to_archive" />{' '}
              <span style={{ fontWeight: 500, fontStyle: 'italic' }}>{storeName}</span>
              {', '}
              <Translate id={dialogDescription} />
            </Typography>
          </DialogContentText>

          <TextField
            variant="standard"
            inputProps={{ 'data-fd': 'archive-store-textfield' }}
            autoFocus
            disabled={isSubmitting}
            required
            placeholder={storeName}
            error={validationError}
            margin="none"
            style={{ width: '100%' }}
            onChange={(e) => setTextField(e.target.value)}
            helperText={validationError ? <Translate id={helperText} /> : ''}
          />
        </DialogContent>
        <DialogActions className={classes.addPadding}>
          <Button
            data-fd={`cancel-archive-store`}
            onClick={closeDialog}
            color="primary"
            disabled={isSubmitting}
          >
            <Translate id="Cancel" />
          </Button>

          <LoadingButton
            fdKey={`confirm-archive-store`}
            onClick={_handleSubmit}
            color="secondary"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            <Translate id="Archive" />
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ArchiveStoreButton;
