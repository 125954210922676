import { SalesChannelType } from '@fd/customHooks/useQueryRMSSalesChannels';

export const getIsFlipdishChannel = (channel: SalesChannelType) =>
  [
    SalesChannelType.FLIPDISH_KIOSK,
    SalesChannelType.FLIPDISH_MOBILE_APP,
    SalesChannelType.FLIPDISH_WEB_APP,
  ].includes(channel || '');

export type SalesChannelsGrouped = typeof salesChannelsGrouped;
export const salesChannelsGrouped = {
  pos: [{ value: SalesChannelType.POS, label: 'POS' }],
  kiosk: [{ value: SalesChannelType.FLIPDISH_KIOSK, label: 'Flipdish Kiosk' }],
  'mobile-apps': [
    {
      value: SalesChannelType.FLIPDISH_MOBILE_APP,
      label: 'Flipdish Mobile App',
    },
  ],
  website: [
    {
      value: SalesChannelType.FLIPDISH_WEB_APP,
      label: 'Flipdish Web App',
    },
  ],
  marketplaces: [
    { value: SalesChannelType.JUST_EATS, label: 'Just Eat' },
    { value: SalesChannelType.UBER_EATS, label: 'Uber Eats' },
    { value: SalesChannelType.DELIVEROO, label: 'Deliveroo' },
  ],
};

export const getSalesChannelTypeSuffix = (channel?: SalesChannelType) => {
  switch (channel) {
    case SalesChannelType.FLIPDISH_KIOSK:
      return 'Kiosk';
    case SalesChannelType.FLIPDISH_MOBILE_APP:
      return 'Mobile App';
    case SalesChannelType.FLIPDISH_WEB_APP:
      return '';
    case SalesChannelType.POS:
      return 'POS';
    case SalesChannelType.JUST_EATS:
      return 'Just Eat';
    case SalesChannelType.UBER_EATS:
      return 'Uber Eats';
    case SalesChannelType.DELIVEROO:
      return 'Deliveroo';
    default:
      return '';
  }
};

export const getSalesChannelGroupByLabel = (label?: string) => {
  if (label) {
    for (const group of Object.values(salesChannelsGrouped)) {
      if (group.some((option) => option.value === label)) {
        return group;
      }
    }
  }
  return undefined;
};

export const getBackToSalesChannelListUrl = ({ appId, pathname, propertyId = '' }) => {
  switch (true) {
    case pathname.includes('website'):
      return `/${appId}/sales-channels/website?tab=sales-channels`;
    case pathname.includes('kiosk'):
      return `/${appId}/sales-channels/kiosk?tab=sales-channels`;
    case pathname.includes('properties'):
      return `/${appId}/properties/${propertyId}?tab=sales-channels`;
    case pathname.includes('marketplaces'):
      return `/${appId}/sales-channels/marketplaces?tab=sales-channels`;
    case pathname.includes('mobile-apps'):
      return `/${appId}/sales-channels/mobile-apps?tab=sales-channels`;
    default:
      return `/${appId}/sales-channels/point-of-sale?tab=sales-channels`;
  }
};

export const getSalesChannelTypeFromUrl = (pathname: string) => {
  if (pathname.includes('website')) return SalesChannelType.FLIPDISH_WEB_APP;
  if (pathname.includes('kiosk')) return SalesChannelType.FLIPDISH_KIOSK;
  if (pathname.includes('mobile-apps')) return SalesChannelType.FLIPDISH_MOBILE_APP;
  return SalesChannelType.POS;
};
