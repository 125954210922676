import React from 'react';

import useQueryRMSCommonDataHook from '@fd/customHooks/useQueryRMSCommonDataHook';
import { App, Store } from '@flipdish/api-client-typescript';
import EndOfDayReportIcon from '@mui/icons-material/BarChart';
import Fastfood from '@mui/icons-material/FastfoodOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingCart from '@mui/icons-material/ShoppingCartOutlined';
import TimerOffIcon from '@mui/icons-material/TimerOffOutlined';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardHeader from '@mui/material/CardHeader';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import { connect } from 'react-redux';

import { flagService } from '../../../services/flagService';
import LinkIconButton from '../../../ui/Button/LinkIconButton';
import { HORIZONTAL_SPACE_CLASSNAME, VERTICAL_SPACE_CLASSNAME } from '../../../ui/Layout';
import { getStoreByIdFactory } from '../selectors';
import OpeningHourOverrides from './OpeningHourOverrides';
import StoreCardLinkButton from './StoreCardLinkButton';
import {
  cardActionsSkeleton,
  headerActionSkeleton,
  subHeaderSkeleton,
  titleSkeleton,
} from './StoreDetailsCardLoading';
import StoreNetSales from './StoreNetSales';

// #region helper components
const useStoreSettingsLinkStyles = makeStyles({
  root: {
    margin: -6,
  },
});

const StoreSettingsLink = ({ appId, store }: LinkProps) => {
  const classes = useStoreSettingsLinkStyles();
  const { StoreGroupId, StoreId, Name } = store!;
  const toUrl = `/${appId}/storegroups/${StoreGroupId}/stores/${StoreId}`;

  return (
    <Translate>
      {(translate) => (
        <LinkIconButton
          to={toUrl}
          className={classes.root}
          fdKey={`store-settings-${Name}`}
          label={translate('Store_settings') as string}
        >
          <SettingsIcon color="action" />
        </LinkIconButton>
      )}
    </Translate>
  );
};
const OrdersLink = ({ appId, store }: LinkProps) => {
  const { StoreId, Name } = store!;
  const toUrl = `/${appId}/orders/?store=${StoreId}`;

  return (
    <StoreCardLinkButton to={toUrl} fdKey={`view-orders-${Name}`} icon={ShoppingCart}>
      <Translate id="Orders" />
    </StoreCardLinkButton>
  );
};
const HourOverridesLink = ({ appId, store }: LinkProps) => {
  const { StoreGroupId, StoreId, Name } = store!;
  const toUrl = `/${appId}/storegroups/${StoreGroupId}/stores/${StoreId}/openinghouroverrides/`;

  return (
    <StoreCardLinkButton to={toUrl} fdKey={`opening-hours-overrides-${Name}`} icon={TimerOffIcon}>
      <Translate id="hours_override" />
    </StoreCardLinkButton>
  );
};
const MenusLink = ({ appId, store }: LinkProps) => {
  const { MenuId, Name } = store!;
  const toUrl = `/${appId}/menus/${MenuId}`;

  return (
    <StoreCardLinkButton to={toUrl} fdKey={`edit-menus-${Name}`} icon={Fastfood}>
      <Translate id="Menu" />
    </StoreCardLinkButton>
  );
};
const EndOfDayReportLink = ({ appId, store }: LinkProps) => {
  const { StoreId, Name } = store!;
  const toUrl = `/${appId}/reports/endofday?store=${StoreId}`;

  return (
    <StoreCardLinkButton to={toUrl} fdKey={`end-of-day-report-${Name}`} icon={EndOfDayReportIcon}>
      <Translate id="End_of_day_report" />
    </StoreCardLinkButton>
  );
};
const CardTitle = ({ title }) => {
  return (
    <Typography variant="h4" component="h2">
      {title}
    </Typography>
  );
};
// #endregion

const useStyles = makeStyles(({ breakpoints }: Theme) => ({
  root: {
    overflow: 'visible',
    width: '100%',
    wordBreak: 'break-word',

    [breakpoints.down('md')]: {
      borderRadius: 0,
      boxShadow: 'none',
      borderTop: '1px solid rgba(0, 0, 0, 0.2)',
    },
  },
  cardActions: {
    padding: '16px 16px 16px 8px',
    [breakpoints.down('lg')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 16,
    },
  },
}));

type LinkProps = {
  appId?: string;
  store?: Store;
};
type Props = MappedState & IStoreDetailsCardProps;
export interface IStoreDetailsCardProps {
  storeId: number;
  inView?: boolean;
}

const StoreDetailsCard: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    store,
    appId,
    inView,
    accessLevel,
    internationalisedAddressConfig,
    isHideStoresPageFlagOn,
  } = props;

  const { data } = useQueryRMSCommonDataHook(appId ?? '');
  const isApiFlagOn = data?.associations?.isMigratedToSalesChannels;
  const classes = useStyles();
  const hideStoresPage = isApiFlagOn && isHideStoresPageFlagOn;
  const showDynamicAddressFields = internationalisedAddressConfig?.affectedCountries?.includes(
    store?.Address?.CountryCode
  );

  const legacyDisplayForCustomer = store ? store.Address?.DisplayForCustomer : subHeaderSkeleton;
  const formattedAddressI18n = store?.Address?.SingleLineDisplay ?? legacyDisplayForCustomer;

  let storeSettingsLink: React.ReactElement | null = null;

  if (!hideStoresPage) {
    storeSettingsLink = store ? (
      <StoreSettingsLink store={store} appId={appId} />
    ) : (
      headerActionSkeleton
    );
  }
  return (
    <Card className={classes.root}>
      <OpeningHourOverrides storeId={store?.StoreId} inView={inView} />

      <CardHeader
        className={`${HORIZONTAL_SPACE_CLASSNAME} ${VERTICAL_SPACE_CLASSNAME}`}
        action={storeSettingsLink}
        title={store ? <CardTitle title={store.Name} /> : titleSkeleton}
        subheader={
          <Typography variant="body2" color="textSecondary">
            {showDynamicAddressFields ? formattedAddressI18n : legacyDisplayForCustomer}
          </Typography>
        }
      />

      {accessLevel !== App.AppAccessLevelEnum.StoreStaff ? (
        <StoreNetSales storeId={store?.StoreId} inView={inView}>
          <Hidden mdDown>
            <Divider />
          </Hidden>
        </StoreNetSales>
      ) : null}

      <Hidden mdDown>
        <Divider />
      </Hidden>
      <CardActions
        disableSpacing
        className={`${classes.cardActions} ${HORIZONTAL_SPACE_CLASSNAME}`}
      >
        {store ? (
          <>
            <OrdersLink store={store} appId={appId} />
            <HourOverridesLink store={store} appId={appId} />
            <MenusLink store={store} appId={appId} />
            <EndOfDayReportLink store={store} appId={appId} />
          </>
        ) : (
          cardActionsSkeleton
        )}
      </CardActions>
    </Card>
  );
};

type MappedState = ReturnType<ReturnType<typeof mapStateToPropsFactory>>;
const mapStateToPropsFactory = (state, props: IStoreDetailsCardProps) => {
  const getStoreById = getStoreByIdFactory(props.storeId);
  const internationalisedAddressConfig = flagService.getSplitValueConfig(
    state,
    'internationalisedAddress'
  )?.config;
  const hideStoresPage = flagService.isFlagOn(state, 'hideStoresPage2');
  return (state: AppState) => {
    const store = getStoreById(state);
    const appId = state.currentApp.AppId;
    const accessLevel = state.currentApp.AppAccessLevel;
    const isStorePublished = store && store.IsPublished;

    return {
      store,
      appId,
      accessLevel,
      isStorePublished,
      internationalisedAddressConfig,
      isHideStoresPageFlagOn: hideStoresPage,
    };
  };
};

export default connect(mapStateToPropsFactory)(StoreDetailsCard);
