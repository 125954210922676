import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { LoyaltyCampaign } from '@flipdish/api-client-typescript';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { type Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { type FormikProps, Form, withFormik } from 'formik';
import { getTranslate, Translate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import YesNoDialog from '@fd/ui/Dialog/YesNoDialog';
import { FkNumeric } from '@fd/ui/FkNumeric/FkNumeric';
import { FkRadioGroup } from '@fd/ui/FkRadioGroup/FkRadioGroup';
import FkSelect from '@fd/ui/FkSelect/FkSelect';
import { FkSwitch } from '@fd/ui/FkSwitch/FkSwitch';
import FormItemLayout from '@fd/ui/Forms/FormItemLayout';
import { Modal } from '@fd/ui/Modal/Modal';
import { ModalActions } from '@fd/ui/Modal/ModalActions';
import { ModalContent } from '@fd/ui/Modal/ModalContent';
import { OptionType } from '@fd/ui/Select/Select';
import InfoIcon from '@fd/ui/Tooltip/InfoIcon';
import Tooltip from '@fd/ui/Tooltip/Tooltip';

import { FIELDS } from '../../../models/fields';
import { flagService } from '../../../services';
import { getStores } from '../selectors';
import ActionButtonGroup from './ActionButtonGroup';
import AssignStoresField from './AssignStoresField';

const useStyles = makeStyles((theme: Theme) => ({
  switch: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  lowerCase: {
    textTransform: 'lowercase',
  },
  endAdornment: {
    whiteSpace: 'nowrap',
  },
  icon: {
    display: 'inline',
    marginLeft: theme.spacing(0.5),
  },
  removeDialog: {
    whiteSpace: 'pre-line',
  },
  freeMealType: {
    paddingBottom: theme.spacing(2),
  },
}));

type InnerProps = MappedProps & FormikProps<FormValues>;
type OuterProps = {
  campaignData?: LoyaltyCampaign | undefined;
  editMode: boolean;
  onCancel: () => void;
  onCreate: (values: FormValues) => void;
  onRemove: (CampaignId: number) => void;
  open: boolean;
};

type Props = InnerProps & OuterProps;

export enum TypesOfReward {
  FREE_MEAL = 'Free meal',
  DISCOUNT = 'Discount',
}

export type FormValues = ReturnType<typeof getDefaultFormState>;

export const getDefaultFormState = ({ campaignData = {} as LoyaltyCampaign, editMode }) => {
  const formState = {
    AutoApplyResultingVouchers: false,
    IncludeDeliveryFee: false,
    IncludeExistingOrders: false,
    IsEnabled: true,
    OrdersBeforeReceivingVoucher: 10,
    PercentDiscountAmount: undefined,
    RoundingStrategy: 0,
    ShouldIncludeOrdersWithLoyaltyVoucher: true,
    StoreIds: [],
    VoucherValidPeriodDays: 10,
  } as LoyaltyCampaign;

  if (editMode) {
    return {
      ...formState,
      ...campaignData,
      ShouldIncludeOrdersWithLoyaltyVoucher:
        typeof campaignData.ShouldIncludeOrdersWithLoyaltyVoucher === 'boolean'
          ? campaignData.ShouldIncludeOrdersWithLoyaltyVoucher
          : true,
      RoundingStrategy: campaignData.RoundingStrategy ? campaignData.RoundingStrategy : 0,
      PercentDiscountAmount: campaignData.PercentDiscountAmount
        ? campaignData.PercentDiscountAmount
        : '',
      TypeOfReward: campaignData.PercentDiscountAmount
        ? TypesOfReward.DISCOUNT
        : TypesOfReward.FREE_MEAL,
    };
  }
  return {
    ...formState,
    TypeOfReward: TypesOfReward.FREE_MEAL,
  };
};

const LoyaltyCampaignModal = (props: Props) => {
  const classes = useStyles();
  const {
    allStores,
    editMode,
    initialValues,
    isLoyaltyMaxDiscountOn,
    onCancel,
    onRemove,
    translate,
    values,
  } = props;

  const [selectedStores, setSelectedStores] = useState<OptionType[]>();
  const [isRemoveDialogVisible, setIsRemoveDialogVisible] = useState(false);

  const titleTextId = editMode ? 'Modify_loyalty_campaign' : 'Create_new_loyalty_campaign';

  const roundOffVoucherOptions = useMemo(
    () => [
      { value: 0, label: translate('Up_to_nearest_1') as TranslationId },
      { value: 1, label: translate('Up_to_nearest_050') as TranslationId },
      { value: 2, label: translate('No_rounding') as TranslationId },
    ],
    []
  );

  useEffect(() => {
    if (initialValues.StoreIds?.length) {
      const assignedStores = allStores
        .filter((all) => initialValues.StoreIds?.some((assigned) => assigned === all.StoreId))
        .map((s) => {
          return {
            label: s.StoreName as string,
            value: s.StoreId as number,
          };
        });
      setSelectedStores(assignedStores);
    }
  }, [allStores]);

  const handleRemoveClick = useCallback(() => {
    setIsRemoveDialogVisible(true);
  }, []);

  return (
    <>
      <Modal titleTextId={titleTextId as TranslationId} open={props.open} onClose={onCancel}>
        <Form>
          <ModalContent>
            <Divider />
            <FormItemLayout
              bottomBorder
              labelId="Is_the_campaign_enabled"
              mdProportion="10x2"
              noWrap
              removeRootPaddings
              smProportion="10x2"
            >
              <Box className={classes.switch}>
                <FkSwitch {...FIELDS['CampaignIsEnabled']} name={'IsEnabled'} />
              </Box>
            </FormItemLayout>

            <FormItemLayout
              descriptionId="Campaign_sales_channel_item_description"
              labelId="Sales_channels"
              mdProportion="12x12"
              removeRootPaddings
            >
              <AssignStoresField fieldName={'StoreIds'} preSelectedStores={selectedStores} />
            </FormItemLayout>

            <Box pt={2}>
              <FormItemLayout
                bottomBorder
                descriptionId={
                  editMode ? 'This_field_can_not_be_modified_for_existing_campaigns' : undefined
                }
                labelId="Give_a_free_voucher_after"
                mdProportion="8x4"
                removeRootPaddings
                showTooltip
                smProportion="8x4"
                tooltipOptions={{
                  fdKey: 'Tooltip-Give-a-Free-Voucher-After',
                  messageId: 'Give_a_free_voucher_after_tooltip' as TranslationId,
                }}
              >
                <FkNumeric
                  {...FIELDS['CampaignOrdersBeforeReceivingVoucher']}
                  disabled={editMode}
                  name={'OrdersBeforeReceivingVoucher'}
                  renderEndAdornmen={() => (
                    <span className={classes.lowerCase}>
                      <Translate id="Orders" />
                    </span>
                  )}
                />
              </FormItemLayout>
            </Box>

            <FormItemLayout
              bottomBorder
              labelId="Voucher_will_be_valid_for"
              mdProportion="8x4"
              removeRootPaddings
              smProportion="8x4"
            >
              <FkNumeric
                {...FIELDS['CampaignVoucherValidPeriodDays']}
                name={'VoucherValidPeriodDays'}
                renderEndAdornmen={() => (
                  <span className={classes.lowerCase}>
                    <Translate id="Days" />
                  </span>
                )}
              />
            </FormItemLayout>

            <FormItemLayout
              bottomBorder
              labelId="Does_it_cover_delivery_fees"
              mdProportion="10x2"
              noWrap
              removeRootPaddings
              smProportion="10x2"
            >
              <Box className={classes.switch}>
                <FkSwitch {...FIELDS['CampaignIncludeDeliveryFee']} name={'IncludeDeliveryFee'} />
              </Box>
            </FormItemLayout>

            <FormItemLayout
              bottomBorder
              labelId="Does_it_apply_automatically_to_orders"
              mdProportion="10x2"
              noWrap
              removeRootPaddings
              smProportion="10x2"
            >
              <Box className={classes.switch}>
                <FkSwitch
                  {...FIELDS['CampaignAutomaticallyApplyExistingOrders']}
                  name={'AutoApplyResultingVouchers'}
                />
              </Box>
            </FormItemLayout>

            <FormItemLayout
              bottomBorder
              descriptionId={
                editMode
                  ? 'This_field_can_not_be_modified_for_existing_campaigns'
                  : 'Include_existing_orders_item_description'
              }
              labelId="Includes_existing_orders"
              mdProportion="10x2"
              noWrap
              removeRootPaddings
              smProportion="10x2"
            >
              <Box className={classes.switch}>
                <FkSwitch
                  {...FIELDS['CampaignIncludeExistingOrders']}
                  disabled={editMode}
                  name={'IncludeExistingOrders'}
                />
              </Box>
            </FormItemLayout>

            <FormItemLayout
              bottomBorder
              labelId="Force_discount"
              descriptionId="Force_discount_description"
              mdProportion="10x2"
              noWrap
              removeRootPaddings
              smProportion="10x2"
            >
              <Box className={classes.switch}>
                <FkSwitch {...FIELDS['CampaignForceDiscount']} name={'ForceDiscount'} />
              </Box>
            </FormItemLayout>

            <FormItemLayout
              alignItems="center"
              description={
                <div>
                  <span aria-describedby="campaign_tooltip">
                    <Translate id="Campaign_types_of_reward_item_description" />
                  </span>
                  <Tooltip
                    id="campaign_tooltip"
                    messageId="Campaign_discount_description_alt"
                    fdKey="campaign_tooltip"
                  >
                    <div className={classes.icon}>
                      <InfoIcon size={13} verticalAlign="text-top" />
                    </div>
                  </Tooltip>
                </div>
              }
              labelId="Type_of_reward"
              mdProportion="12x12"
              removeRootPaddings
              smProportion="12x12"
            >
              <FkRadioGroup
                {...FIELDS['CampaignTypeOfReward']}
                name={'TypeOfReward'}
                options={[{ value: TypesOfReward.FREE_MEAL }, { value: TypesOfReward.DISCOUNT }]}
                renderEndAdornment={(optionValue) => {
                  return optionValue === TypesOfReward.FREE_MEAL ? (
                    <Translate id="Free_meal" />
                  ) : (
                    <Translate id="Percentage_discount" />
                  );
                }}
              />
            </FormItemLayout>

            {values.TypeOfReward === TypesOfReward.FREE_MEAL && (
              <Box pb={3.75}>
                <FormItemLayout
                  className={classes.freeMealType}
                  bottomBorder
                  descriptionId="Round_off_voucher_description"
                  labelId="Round_off_voucher"
                  mdProportion="12x12"
                  removeRootPaddings
                >
                  <FkSelect
                    {...FIELDS['CampaignRoundingStrategy']}
                    menuPlacement="top"
                    name={'RoundingStrategy'}
                    options={roundOffVoucherOptions}
                    removeTopPadding
                    variant="standard"
                  />
                </FormItemLayout>
              </Box>
            )}

            {values.TypeOfReward === TypesOfReward.DISCOUNT && (
              <Box pb={1.75}>
                <FormItemLayout
                  bottomBorder={!isLoyaltyMaxDiscountOn}
                  descriptionId="Discount_amount_description"
                  labelId="Discount_amount"
                  mdProportion="12x12"
                  removeRootPaddings
                >
                  <Box pt={1}>
                    <FkNumeric
                      {...FIELDS['CampaignPercentDiscountAmount']}
                      name={'PercentDiscountAmount'}
                      removeTopPadding
                      renderEndAdornmen={() => (
                        <Box className={classes.endAdornment}>
                          <Translate id="Percentage_off" />
                        </Box>
                      )}
                    />
                  </Box>
                </FormItemLayout>

                {isLoyaltyMaxDiscountOn && (
                  <FormItemLayout
                    bottomBorder
                    descriptionId="Max_discount_amount_description"
                    labelId="Max_discount_amount"
                    mdProportion="12x12"
                    removeRootPaddings
                    showBetaTag
                  >
                    <FkNumeric {...FIELDS['CampaignMaxDiscount']} name={'MaxDiscount'} />
                  </FormItemLayout>
                )}
              </Box>
            )}
          </ModalContent>

          <ModalActions>
            <ActionButtonGroup
              editMode={editMode}
              onCancel={onCancel}
              onRemove={handleRemoveClick}
            />
          </ModalActions>
        </Form>
      </Modal>

      <YesNoDialog
        noTextId={'Cancel'}
        onNo={() => setIsRemoveDialogVisible(false)}
        onYes={() => {
          setIsRemoveDialogVisible(false);
          onRemove(initialValues.CampaignId as number);
        }}
        open={isRemoveDialogVisible}
        titleTextId={'Remove_loyalty_campaign'}
        yesTextId={'Remove'}
      >
        <Typography variant="body1" className={classes.removeDialog}>
          <Translate id="Pressing_remove_will_delete" />
        </Typography>
      </YesNoDialog>
    </>
  );
};

type MappedProps = ReturnType<typeof mapStateToProps>;
const mapStateToProps = (state: AppState) => {
  const { locale } = state;

  return {
    allStores: getStores(state),
    isLoyaltyMaxDiscountOn: flagService.isFlagOn(state, 'loyaltyMaxDiscount'),
    translate: getTranslate(locale),
  };
};

export default compose<InnerProps, OuterProps>(
  connect(mapStateToProps, null),
  withFormik<Props, FormValues>({
    displayName: 'LoyaltyCampaignForm',
    enableReinitialize: true,
    mapPropsToValues: (props) => {
      return getDefaultFormState(props);
    },
    handleSubmit: async (values, formikBag) => {
      const adjustedValues =
        values.TypeOfReward === TypesOfReward.FREE_MEAL
          ? { ...values, PercentDiscountAmount: undefined, MaxDiscount: undefined }
          : { ...values, RoundingStrategy: undefined };
      await formikBag.props.onCreate(adjustedValues as FormValues);
      formikBag.setSubmitting(false);
    },
  })
)(LoyaltyCampaignModal);
