import { useEffect, useState } from 'react';

import { Loader } from '@googlemaps/js-api-loader';

import { GMAP_API_KEY } from '../../constants/map.constant';
import { GoogleMapTools } from './types/GoogleMapTypes';

type GoogleMapToolsAsyncAwait = {
  getPlacePredictions: (
    address: string,
    location: { lat: number; lng: number }
  ) => Promise<google.maps.places.AutocompletePrediction[] | null>;
  getPlaceDetails: (placeId: string) => Promise<google.maps.places.PlaceResult | null>;
  geoCode: (address: string) => Promise<google.maps.places.PlaceResult[] | null>;
};

export function useMapTools(): GoogleMapToolsAsyncAwait | undefined {
  const googleMapApiKey = GMAP_API_KEY ?? '';

  const [mapTools, setMapTools] = useState<GoogleMapTools | undefined>(undefined);

  useEffect(() => {
    if (typeof google !== 'undefined' && google?.maps?.places) {
      const newMapTools = {
        AutoComplete: new google.maps.places.AutocompleteService(),
        GeoCoder: new google.maps.Geocoder(),
        Places: new google.maps.places.PlacesService(document.createElement('div')),
      };
      setMapTools(newMapTools);
    } else if (googleMapApiKey) {
      (async () => {
        try {
          const loader = new Loader({
            apiKey: googleMapApiKey,
          });
          const { Geocoder } = await loader.importLibrary('geocoding');
          const { AutocompleteService, PlacesService } = await loader.importLibrary('places');
          const newMapTools = {
            AutoComplete: new AutocompleteService(),
            GeoCoder: new Geocoder(),
            Places: new PlacesService(document.createElement('div')),
          };

          setMapTools(newMapTools);
        } catch (e: any) {
          window.fdlogger?.error('Failed to load map tools!', {
            message: e?.message,
          });
        }
      })();
    }
  }, []);

  function getPlacePredictions(address: string, location: { lat: number; lng: number }) {
    return new Promise<google.maps.places.AutocompletePrediction[] | null>((resolve, reject) => {
      mapTools?.AutoComplete.getPlacePredictions(
        {
          input: address,
          types: ['establishment'],
          location: new google.maps.LatLng(location),
          radius: 200,
        },
        (suggestions, status) => {
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            reject(new Error('Failed to get place predictions'));
            return;
          }
          resolve(suggestions);
        }
      );
    });
  }

  function getPlaceDetails(placeId: string) {
    return new Promise<google.maps.places.PlaceResult | null>((resolve, reject) => {
      const request = {
        placeId,
        fields: ['address_component', 'international_phone_number', 'name', 'geometry.location'],
      };

      mapTools?.Places.getDetails(request, (result, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          reject(new Error('Failed to get place details'));
          return;
        }
        resolve(result);
      });
    });
  }

  function geoCode(address: string) {
    return new Promise<google.maps.GeocoderResult[] | null>((resolve, reject) => {
      mapTools?.GeoCoder.geocode({ address }, (result, status) => {
        if (status !== google.maps.GeocoderStatus.OK) {
          reject(new Error('Failed to geocode'));
          return;
        }
        resolve(result);
      });
    });
  }

  return { geoCode, getPlacePredictions, getPlaceDetails };
}
