import React, { useEffect, useRef, useState } from 'react';

import useLoadStoreFromSalesChannelIdIntoRedux from '@fd/customHooks/useLoadStoreFromSalesChannelIdIntoRedux';
import { App } from '@flipdish/api-client-typescript';
import CircularProgress from '@mui/material/CircularProgress';
import { type Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import * as storeConstants from '../../../constants/store.constants';
import {
  createLoadingErrorSelectorFactory,
  createLoadingSelector,
} from '../../../selectors/loading.selector';
import { getSelectedStore } from '../../../selectors/store.selector';
import { useTracking } from '../../../services/amplitude/useTracking';
import PageLayout from '../../../ui/Layout';
import PaperContainer from '../../../ui/Layout/PaperContainer';
import {
  attachBankAccountToStore,
  getAttachedBankAccount,
  getBankAccounts,
} from '../../Finance/Banking/banking.actions';
import StoreBankingSettingsForm from './StoreBankingSettingsForm';

const initialLoadingSelector = createLoadingSelector([storeConstants.STORE_LOAD]);
const initialLoadingErrorSelector = createLoadingErrorSelectorFactory([storeConstants.STORE_LOAD]);

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingTop: `${theme.spacing(1)} !important`,
  },
  item: {
    width: '100%',
    maxWidth: '100%',
    padding: '8px',
    minHeight: '24px',
  },
  select: {
    width: '100%',
    maxWidth: '100%',
    paddingRight: '16px',
  },
  buttons: {
    justifyContent: 'space-between',
  },
  button: {
    fontSize: '14px',
    minWidth: '140px',
    fontWeight: '500' as any,
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: '1.4',
    letterSpacing: '1.3px',
  },
  textButton: {
    padding: '4px 0px 4px 0px',
    minHeight: '24px',
    maxHeight: '24px',
    lineHeight: '1.5',
    letterSpacing: '0.2px',
    textTransform: 'none',
    [theme.breakpoints.down('md')]: {
      padding: '4px 0px 4px 0px',
    },
  },
}));

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> & { dispatch: ThunkDispatch };

const StoreBankingSettings = (props: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    account,
    accounts,
    attachBankAccount,
    isSuccess,
    loadBankAccount,
    loadBanking,
    loading,
    loadingError,
    selectedApp,
    store,
    translate,
  } = props;

  const prevBrandIdRef = useRef<string | null>(null);
  const brandId = store?.AppIds ? store.AppIds[0] : '';
  const [isLoadingBanking, setIsLoadingBanking] = useState(false);
  const isLoading = isLoadingBanking || !store || !accounts || loading;

  const { storeId } = useLoadStoreFromSalesChannelIdIntoRedux({ store });
  const { trackEvent } = useTracking();
  useEffect(() => {
    trackEvent('portal_storeGroups_stores_banking_settings', {
      action: 'logged_in',
    });
  }, []);

  useEffect(() => {
    if (storeId) {
      setIsLoadingBanking(true);
      loadBankAccount(storeId).finally(() => {
        setIsLoadingBanking(false);
      });
    }
  }, [storeId]);
  useEffect(() => {
    const isDifferentBrand = brandId !== prevBrandIdRef.current;

    if (isDifferentBrand || (!accounts.length && storeId)) {
      setIsLoadingBanking(true);

      prevBrandIdRef.current = brandId;
      loadBanking(brandId).finally(() => {
        setIsLoadingBanking(false);
      });
    }
  }, [brandId, accounts.length, storeId]);

  useEffect(() => {
    if (props.isSuccess) {
      enqueueSnackbar('Success', {
        ariaAttributes: { 'aria-describedby': 'client-snackbar' },
        variant: 'success',
      });
    }
  }, [props.isSuccess]);

  if (loadingError) {
    return <Redirect to={'/' + selectedApp.AppId + '/storegroups'} />;
  }

  if (isSuccess && store) {
    return (
      <Redirect
        to={`/${selectedApp.AppId}/storegroups/${store.StoreGroupId}/stores/${store.StoreId}`}
      />
    );
  }

  return (
    <PageLayout
      toParent={
        store
          ? `/${selectedApp.AppId}/storegroups/${store.StoreGroupId}/stores/${store.StoreId}`
          : ''
      }
      documentTitle="Store_banking_settings_title"
      title={
        store?.Name || (translate('Add_and_edit_bank_account_connected_to_this_sales') as string)
      }
    >
      <PaperContainer className={classes.container}>
        {isLoading ? (
          <CircularProgress style={{ margin: '10px' }} size={50} />
        ) : (
          <StoreBankingSettingsForm
            submit={attachBankAccount}
            storeId={store && store.StoreId}
            appId={brandId}
            classes={classes}
            initialValues={account}
          />
        )}
      </PaperContainer>
    </PageLayout>
  );
};

const mapStateToProps = (state: AppState) => {
  const canEdit = state.permissions.some(
    (p) => p === App.AppResourceSetEnum.ViewAssignedBankAccount.toString()
  );
  const { locale } = state;
  return {
    account: state.stores.bankAccountId,
    accounts: state.banking.accounts,
    canEdit,
    isSuccess: state.banking.isSuccess,
    loading: initialLoadingSelector(state),
    loadingError: initialLoadingErrorSelector(state),
    selectedApp: state.currentApp,
    store: getSelectedStore(state),
    translate: getTranslate(locale),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch) => {
  return {
    loadBanking: (brandId: string) => {
      return dispatch(getBankAccounts(brandId));
    },
    attachBankAccount: (account, storeId) =>
      dispatch(attachBankAccountToStore(account.value, storeId as number, true)),
    loadBankAccount: (storeId) => {
      return dispatch(getAttachedBankAccount(storeId as number));
    },
  };
};

const EnhancedComponent = connect(mapStateToProps, mapDispatchToProps)(StoreBankingSettings);

export default EnhancedComponent;
