import React from 'react';

import { useAppDispatch } from '@fd/customHooks/useAppDispatch';
import { Org } from '@flipdish/orgmanagement';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { notifyError } from '../../../layouts/Notify/actions';
import { getBrandsForOrg } from '../organisation.services';
import { setCurrentOrg } from '../rms.actions';
import { OrgAndBrandSelectorItems } from './OrgAndBrandSelectorItems';

type Props = {
  orgs: Org[];
  open: boolean;
  hasSearched?: boolean;
  searchQuery: string;
  toggleOrgDrawer: () => void;
  setLoadingbrandsForSelectedOrg: (val: boolean) => void;
  setSearchQuery: (val: string) => void;
};

const OrgSelectorItems = ({
  orgs,
  open,
  hasSearched,
  searchQuery,
  toggleOrgDrawer,
  setLoadingbrandsForSelectedOrg,
  setSearchQuery,
}: Readonly<Props>) => {
  const translate = useSelector(getTranslate);
  const history = useHistory();
  const currentOrg = useSelector((state: AppState) => state.rms.currentOrg);
  const dispatch = useAppDispatch();

  const handleOrgSelect = async (org: Org, shouldOpenLinkInNewTab: boolean) => {
    if (!org?.orgId) {
      dispatch(
        notifyError({
          message: translate('No_orgId_found_for_org_name', { name: org?.name }) as string,
          translate: false,
        })
      );
      return;
    }
    if (org.orgId === currentOrg?.orgId) {
      toggleOrgDrawer();
      return;
    }
    try {
      setLoadingbrandsForSelectedOrg(true);
      const brandResponseForOrg = await getBrandsForOrg(org.orgId);
      const brandId = brandResponseForOrg?.data?.data?.[0]?.brandId;
      if (brandId) {
        if (shouldOpenLinkInNewTab) {
          window.open(`/${brandId}/home`, '_blank');
          return;
        }
        toggleOrgDrawer();
        setSearchQuery('');
        dispatch(setCurrentOrg());
        history.push(`/${brandId}/home`);
      } else {
        dispatch(
          notifyError({
            message: translate('No_brands_found', { orgId: org.orgId }) as string,
            translate: false,
          })
        );
      }
    } catch (e) {
      console.error(e);
      dispatch(notifyError({ message: 'Something_went_wrong' }));
    } finally {
      setLoadingbrandsForSelectedOrg(false);
    }
  };

  return (
    <OrgAndBrandSelectorItems<Org>
      items={orgs}
      onItemSelect={handleOrgSelect}
      noResultsText={translate('No_orgs_found_for', { searchTerm: searchQuery }) as string}
      getItemId={(org) => org.orgId}
      getDisplayProps={(org) => ({
        primary: org.name,
        secondary: org.orgId ?? '',
        imgUrl: org.logoImageUrl,
      })}
      hasSearched={hasSearched}
      open={open}
    />
  );
};

export default OrgSelectorItems;
