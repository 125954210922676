import axios from 'axios';

import { mapServerError } from '../../services/utils/serverErrorMapper';

const isProd = window.location?.host?.includes('portal.flipdish.com');
const rmsBaseUrl = isProd
  ? 'https://rms-core-api.portal.flipdish.com/api/core'
  : 'https://dev-rms-core.portal.flipdishdev.com/api/core';

const rmsApi = axios.create({
  baseURL: rmsBaseUrl,
  withCredentials: true,
});
export type RMSExchangeResponse = {
  data: {
    token: string;
  };
};

// #region getRMSPriceBands
export const getRMSPriceBandsKey = 'getRMSPriceBands';
export async function getRMSPriceBands(franchisorId: string): Promise<any> {
  try {
    return await rmsApi.get(`/organizations/${franchisorId}/lookups/priceBands`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getRMSPriceBands
export const getRMSMenusKey = 'getRMSMenus';
export async function getRMSMenus(franchisorId: string): Promise<any> {
  try {
    return await rmsApi.get(`/organizations/${franchisorId}/menuManagement/latest`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region invalidatePassportCache
export async function invalidatePassportCache(): Promise<any> {
  try {
    return await rmsApi.post(`/system/security/invalidateUserPrivilegesAndPassportCache`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getAssociations
type OrgHierarchy = {
  franchisor: Franchisor;
  franchisees: Array<Franchisee>;
  properties: Array<Property>;
};
interface OrgFieldAssociations {
  flipdish: {
    orgId: string;
  };
}

interface PropertyFieldAssociations {
  flipdish: {
    propertyId: string;
  };
}

interface Franchisor {
  code: string;
  country: string;
  displayResellerBranding: boolean;
  emailAddress: string;
  id: string;
  isBlocked: boolean;
  isEnabled: boolean;
  logoImageUrl: string;
  name: string;
  fieldAssociations: OrgFieldAssociations;
  isMigratedToSalesChannels?: boolean;
}

interface Franchisee {
  code: string;
  franchisorId: string;
  id: string;
  name: string;
  etag: string;
  partitionKey: string;
  rowKey: string;
  timestamp: string;
}

interface Property {
  address: string;
  addressFields: string;
  code: string;
  country: string;
  emailAddress: string;
  franchiseeId: string;
  franchisorId: string;
  id: string;
  isBlocked: boolean;
  isEnabled: boolean;
  logoImageUrl: string;
  name: string;
  phoneNumber: string;
  timeZone: string;
  type: string;
  fieldAssociations: PropertyFieldAssociations;
  subscriptions: string[];
  storefronts: Storefront[];
}

type StoreFrontAssocitions = {
  flipdish: {
    storeId: string;
  };
};

type Storefront = {
  code: string;
  id: string;
  name: string;
  type: string;
  menuId: string;
  fieldAssociations?: StoreFrontAssocitions | null;
  franchiseeId: string;
  priceBandId: string;
  propertyId: string;
  franchisorId: string;
  brandId: string;
  brand: string;
  menuSource: MenuSource;
};

export type MenuSource = 'RMS' | 'Flipdish' | 'BOTH';

export type AssociationResponse = {
  data: {
    associationType: string;
    internalFieldValue: any;
    orgHierarchy: OrgHierarchy;
    menuSource: MenuSource;
    isMigratedToSalesChannels: boolean;
  };
};

export const getAssociationsKey = 'getAssociations';
export async function getAssociations(appId: string): Promise<AssociationResponse> {
  try {
    return await rmsApi.get(`/associations/orgHierarchy/flipdish/appId/${appId}`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// get storeId's associated with a saleschannel
export const getStoreIdAssociationsKey = 'getStoreIdAssociations';
export async function getStoreIdAssociations(
  salesChannelId: string
): Promise<{ data: { flipdish: { storeId: number } } }> {
  try {
    return await rmsApi.get(`/organizations/storefronts/${salesChannelId}/associations`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

// #endregion
export type BasicConfiguration = {
  code: string;
  country: string;
  displayResellerBranding: boolean;
  emailAddress: string;
  id: string;
  isBlocked: boolean;
  isEnabled: boolean;
  logoImageUrl: string;
  name: string;
  fieldAssociations: {
    flipdish: {
      orgId: string;
    };
  };
};

export const getOrganisationBasicConfigKey = 'getOrganisationBasicConfigKey';
export async function getOrganisationBasicConfig(
  franchisorId: string
): Promise<{ data: BasicConfiguration }> {
  try {
    return await rmsApi.get(`organizations/${franchisorId}/configurations/basicConfiguration`);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const updateOrganisationBasicConfigKey = 'updateOrganisationBasicConfig';
export async function updateOrganisationBasicConfig(
  franchisorId: string,
  config: BasicConfiguration
) {
  try {
    await rmsApi.post(`organizations/${franchisorId}/configurations/basicConfiguration`, config);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const getStoreFrontOperatingConfigurationsKey = 'getStoreFrontOperatingConfigurations';
export async function getStoreFrontOperatingConfigurations(
  franchisorId: string,
  franchiseeId: string,
  propertyId: string,
  storefrontId: string
): Promise<{ data: any }> {
  try {
    return await rmsApi.get(
      `organizations/${franchisorId}/${franchiseeId}/${propertyId}/storefronts/${storefrontId}/configurations/operatingConfigurations`
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const RMSServices = {
  getOrganisationBasicConfig,
  getOrganisationBasicConfigKey,
  updateOrganisationBasicConfig,
  invalidatePassportCache,
};
