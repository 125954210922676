import { SalesChannelType } from '@fd/customHooks/useQueryRMSSalesChannels';
import {
  Configuration,
  CreateSalesChannel,
  CreateSalesChannelForOrgAndProperty201Response,
  SalesChannelsApi,
  UpdateSalesChannel,
} from '@flipdish/orgmanagement';

import { getApiEndPoint } from '../../helpers/apibase';
import { mapServerError } from '../../services/utils/serverErrorMapper';

const baseURL = getApiEndPoint();

const configuration = new Configuration({
  basePath: baseURL,
  useDefaultUserAgent: true,
});

const defaultOptions = {
  withCredentials: true,
};

const salesChannelsApi = new SalesChannelsApi(configuration);

export type CreateSalesChannelProps = {
  orgId: string;
  propertyId: string;
  salesChannel: CreateSalesChannel;
};
// #region createSalesChannel
export async function createSalesChannel({
  orgId,
  propertyId,
  salesChannel,
}: CreateSalesChannelProps): Promise<{ data: CreateSalesChannelForOrgAndProperty201Response }> {
  try {
    return await salesChannelsApi.createSalesChannelForOrgAndProperty(
      { orgId, propertyId, createSalesChannel: salesChannel },
      defaultOptions
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

export type UpdateSalesChannelProps = {
  orgId: string;
  propertyId: string;
  salesChannel: UpdateSalesChannel;
  salesChannelId: string;
};
// #region updateSalesChannel
export async function updateSalesChannel({
  orgId,
  propertyId,
  salesChannel,
  salesChannelId,
}: UpdateSalesChannelProps) {
  try {
    return await salesChannelsApi.updateSalesChannelById(
      { orgId, propertyId, updateSalesChannel: salesChannel, salesChannelId },
      defaultOptions
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}
// #endregion

// #region getSalesChannels
export const getSalesChannelsKey = 'getSalesChannels';
export async function getSalesChannels(values: { orgId: string; propertyId: string }) {
  const { orgId, propertyId } = values;
  try {
    return await salesChannelsApi.getSalesChannelsByOrgIdAndPropertyId(
      {
        orgId,
        propertyId,
        expand: ['hierarchy.linkedSalesChannelIds', 'hierarchy.primarySalesChannelId'],
      },
      defaultOptions
    );
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export async function archiveSalesChannel(values: {
  orgId: string;
  propertyId: string;
  salesChannelId: string;
}) {
  try {
    await salesChannelsApi.archiveSalesChannel(values, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export async function unarchiveSalesChannel(values: {
  orgId: string;
  propertyId: string;
  salesChannelId: string;
}) {
  try {
    await salesChannelsApi.unarchiveSalesChannel(values, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export async function publishSalesChannel(values: {
  orgId: string;
  propertyId: string;
  salesChannelId: string;
}) {
  try {
    return await salesChannelsApi.publishSalesChannel(values, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export async function unpublishSalesChannel(values: {
  orgId: string;
  propertyId: string;
  salesChannelId: string;
}) {
  try {
    await salesChannelsApi.unpublishSalesChannel(values, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

export const cloneSalesChannelKey = 'cloneSalesChannel';
export async function cloneSalesChannel(values: {
  orgId: string;
  propertyId: string;
  salesChannelId: string;
  type: SalesChannelType;
}) {
  try {
    return await salesChannelsApi.cloneSalesChannel(values, defaultOptions);
  } catch (e) {
    const err = await mapServerError(e);
    throw err;
  }
}

const SalesChannelService = {
  archiveSalesChannel,
  unarchiveSalesChannel,
  createSalesChannel,
  updateSalesChannel,
  getSalesChannels,
  getSalesChannelsKey,
  unpublishSalesChannel,
  publishSalesChannel,
};

export default SalesChannelService;
