import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { useAppDispatch } from '@fd/customHooks/useAppDispatch';
import { Brand, Org } from '@flipdish/orgmanagement';
import Drawer from '@mui/material/Drawer';
import { type Theme, styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { notifyError } from '../../../layouts/Notify/actions';
import { AppLoadingCircularProgress } from '../../AppLoadingCircularProgress';
import brandService from '../../Settings/Brands/brands.services';
import organisationService from '../organisation.services';
import BrandSelectorItems from './BrandSelectorItems';
import { BRAND_SEARCH_PREFIX } from './constants';
import OrgAndBrandSelectorItem from './OrgAndBrandSelectorItem';
import OrgAndBrandSelectorSearch from './OrgAndBrandSelectorSearch';
import OrgCreateAdminBtn from './OrgCreateAdminBtn';
import OrgSelectorItems from './OrgSelectorItems';

const drawerWidth = 304;
const limit = 20;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    zIndex: 1350,
  },
  drawer: {
    width: drawerWidth,
    wordBreak: 'break-all',
  },
  drawerOpen: {
    borderRight: 'none',
    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      bottom: 0,
      top: 65,
    },
    right: 0,
  },
  drawerClose: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 65,
    },

    transition: theme.transitions.create('right', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    right: -drawerWidth,
  },
  loadingContainer: {
    height: '100%',
  },
}));

const ListContainer = styled('div')(() => ({
  overflowY: 'auto',
  flex: 1,
  marginBottom: 0,
  height: '100%',
}));

type Props = {
  open: boolean;
  toggleOrgDrawer: () => void;
};

const OrgAndBrandSelector = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const currentOrg = useSelector((state: AppState) => state.rms.currentOrg);
  const dispatch = useAppDispatch();

  const classes = useStyles({ drawerWidth });
  const { open, toggleOrgDrawer } = props;
  const [loading, setLoading] = useState(false);
  const [orgs, setOrgs] = useState<Org[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalOrgsCount, setTotalOrgsCount] = useState(0);
  const [currentTotalOrgsCount, setCurrentTotalOrgsCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const location = useLocation();
  const [loadingbrandsForSelectedOrg, setLoadingbrandsForSelectedOrg] = useState(false);
  const [filteredBrands, setFilteredBrands] = useState<Brand[]>([]);
  const [brandPageNumber, setBrandPageNumber] = useState(0);
  const [currentTotalBrandsCount, setCurrentTotalBrandsCount] = useState(0);
  const [hasSearchedBrands, setHasSearchedBrands] = useState(false);
  const [hasSearchedOrgs, setHasSearchedOrgs] = useState(false);

  useEffect(() => {
    if (location?.search.includes('orgSearch')) {
      const params = new URLSearchParams(location.search);
      const orgSearch = params.get('orgSearch');
      orgSearch && setSearchQuery(orgSearch);
    }
  }, []);

  useEffect(() => {
    handleSearch(searchQuery);
  }, [searchQuery]);

  const loadOrgs = async (page: number, query = '') => {
    setLoading(true);
    try {
      const { data } = await organisationService.getOrgs({
        page,
        pageSize: limit,
        idOrNameContains: query,
      });
      setLoading(false);
      setOrgs([...orgs, ...(data?.data || [])]);
      setHasSearchedOrgs(true);
      setCurrentTotalOrgsCount(data.totalCount ?? 0);

      if (!query && data.totalCount !== totalOrgsCount) {
        setTotalOrgsCount(data.totalCount ?? 0);
      }
    } catch (e) {
      setLoading(false);
      setHasSearchedOrgs(true);
      console.log(e);
      dispatch(notifyError({ message: 'Something_went_wrong', translate: true }));
    }
  };

  const getFurtherOrgs = async () => {
    const newPage = pageNumber + 1;

    setPageNumber(newPage);
    loadOrgs(newPage, searchQuery);
  };

  const handleScroll = ({ target }) => {
    const { scrollHeight, clientHeight, scrollTop } = target;

    // scroll triggers when the compoment goes from has scroll bar to no scroll bar,
    // if there's no scrollbar, we should not fetch more apps.
    // However this hasScrollBar has its problem, see comment from this https://stackoverflow.com/a/5038256
    // it works for us now because we do not hide the overflow
    const hasScrollBar = scrollHeight > clientHeight;
    const scrollTopMax = scrollHeight - clientHeight;

    if (!loading && hasScrollBar && Math.ceil(scrollTop) >= scrollTopMax) {
      if (
        searchQuery.toLowerCase().startsWith(BRAND_SEARCH_PREFIX.toLowerCase()) &&
        brandPageNumber * limit < currentTotalBrandsCount
      ) {
        getFurtherBrands();
      } else if (pageNumber * limit < currentTotalOrgsCount) {
        getFurtherOrgs();
      }
    }
  };

  const handleSearch = useCallback(
    debounce((query: string) => {
      if (query.toLowerCase().startsWith(BRAND_SEARCH_PREFIX.toLowerCase())) {
        setLoading(true);
        setHasSearchedBrands(false);
        loadBrands(1, query);
        setBrandPageNumber(1);
        setOrgs([]);
        setHasSearchedOrgs(false);
      } else {
        setLoading(true);
        setHasSearchedOrgs(false);
        loadOrgs(1, query);
        setPageNumber(1);
        setFilteredBrands([]);
        setHasSearchedBrands(false);
      }
    }, 500),
    []
  );

  const loadBrands = async (page: number, query = '', append = false) => {
    setLoading(true);
    try {
      const searchTerm = query.toLowerCase().replace(BRAND_SEARCH_PREFIX.toLowerCase(), '').trim();

      const { data } = await brandService.getAllBrands({
        idOrNameContains: searchTerm,
        page,
        pageSize: limit,
      });

      setFilteredBrands(append ? (brands) => [...brands, ...(data?.data || [])] : data?.data || []);
      setHasSearchedBrands(true);
      setLoading(false);

      if (data?.totalCount) {
        setCurrentTotalBrandsCount(data.totalCount);
      }
    } catch (e) {
      setLoading(false);
      setHasSearchedBrands(true);
      console.log('Error in loadBrands:', e);
      dispatch(notifyError({ message: 'Something_went_wrong', translate: true }));
    }
  };

  const getFurtherBrands = async () => {
    const newPage = brandPageNumber + 1;
    setBrandPageNumber(newPage);
    loadBrands(newPage, searchQuery, true);
  };

  return (
    <Drawer
      variant={matches ? 'permanent' : 'temporary'}
      anchor="right"
      classes={{
        root: classes.root,
        paper: clsx({
          [classes.drawer]: true,
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
      PaperProps={{ elevation: open ? 10 : 0 }}
      open={open}
      onClose={(e, reason) => {
        if (reason === 'backdropClick') {
          toggleOrgDrawer();
        }
      }}
      ref={ref}
    >
      {currentOrg && (
        <OrgAndBrandSelectorItem
          dataFd={`org-brand-selector-${currentOrg?.orgId}`}
          imgUrl={currentOrg?.logoImageUrl}
          imgName={currentOrg?.name}
          primary={currentOrg?.name}
          secondary={currentOrg?.orgId}
          isHighlighted
        />
      )}
      {(totalOrgsCount > 8 || searchQuery !== '') && (
        <OrgAndBrandSelectorSearch {...{ setSearchQuery, searchQuery, open }} />
      )}
      {open &&
        (loading || loadingbrandsForSelectedOrg ? (
          <div className={classes.loadingContainer}>
            <AppLoadingCircularProgress />
          </div>
        ) : (
          <ListContainer onScroll={handleScroll} data-fd="org-brand-selector-list">
            {searchQuery.toLowerCase().startsWith(BRAND_SEARCH_PREFIX.toLowerCase()) ? (
              <BrandSelectorItems
                brands={filteredBrands}
                hasSearched={hasSearchedBrands}
                open={open}
                searchQuery={searchQuery}
                toggleOrgDrawer={toggleOrgDrawer}
              />
            ) : (
              <OrgSelectorItems
                setSearchQuery={setSearchQuery}
                setLoadingbrandsForSelectedOrg={setLoadingbrandsForSelectedOrg}
                orgs={orgs}
                toggleOrgDrawer={toggleOrgDrawer}
                open={open}
                searchQuery={searchQuery}
                hasSearched={hasSearchedOrgs}
              />
            )}
          </ListContainer>
        ))}
      <OrgCreateAdminBtn toggleOrgDrawer={toggleOrgDrawer} />
    </Drawer>
  );
});

export default OrgAndBrandSelector;
